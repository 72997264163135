
  import { Component, Vue } from 'vue-property-decorator'
  import getApi from '@/api'
  import { CategoryInterface } from '@/interface/BaseInterface'
  import CategoryApi from '../api/CategoryApi'
  import CategoryListView from '../components/category/CategoryListView.vue'
  import { findIndex, filter } from 'lodash'

  const categoryApi: CategoryApi = getApi('category')

  @Component({
    components: { CategoryListView }
  })
  export default class Category extends Vue {
    isLoading = false

    categories: CategoryInterface[] = []

    handleAdd() {
      this.$prompt('Please input category name', this.$t('addCategory') as string, {
        confirmButtonText: this.$t('create') as string,
        cancelButtonText: 'Cancel',
        inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)?/,
        inputErrorMessage: 'Category name  is required'
      }).then((data: any) => {
        categoryApi
          .create({
            name: data.value
          })
          .then(() => {
            this.init()
            this.$message.success(this.$t('createSuccess') as string)
          })
          .catch(() => {
            this.$message.error(this.$t('createErr') as string)
          })
      })
    }

    handleEdit(value: CategoryInterface) {
      this.$prompt('Please input category name', this.$t('editCategory') as string, {
        confirmButtonText: this.$t('edit') as string,
        cancelButtonText: 'Cancel',
        inputValue: value.name,
        inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)?/,
        inputErrorMessage: 'Category name  is required'
      }).then((data: any) => {
        categoryApi
          .update(value._id, {
            name: data.value
          })
          .then(() => {
            const result = findIndex(this.categories, elm => {
              return value._id === elm._id
            })
            if (result !== -1) {
              this.categories[result].name = data.value
            }
            this.$message.success(this.$t('editSuccess') as string)
          })
          .catch(() => {
            this.$message.error(this.$t('editErr') as string)
          })
      })
    }

    async handleRemove(data: CategoryInterface) {
      try {
        await categoryApi.delete(data._id)
        this.categories = filter(this.categories, value => {
          return value._id !== data._id
        })
        this.$message.success(this.$t('removeSuccess') as string)
      } catch (error) {
        this.$message.error(this.$t('removeErr') as string)
      }
    }

    async init() {
      try {
        this.isLoading = true
        this.categories = await categoryApi.all({})
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
      }
    }

    created() {
      this.init()
    }
  }
