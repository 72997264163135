
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import { CategoryInterface } from '@/interface/BaseInterface'

  @Component
  export default class CategoryListView extends Vue {
    @Prop({ required: true, type: Array }) data!: CategoryInterface[]

    handleEdit(data: CategoryInterface) {
      this.$emit('edit', data)
    }

    handleRemove(data: CategoryInterface) {
      this.$emit('remove', data)
    }
  }
